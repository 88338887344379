import { FieldArray, Formik } from 'formik';
import Modal from '../../../components/FormComponents/Modal/Modal';
import * as Yup from 'yup';
import BorderedInput from '../../../components/FormComponents/Inputs/BorderedInput';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import BorderedMaskedInput from '../../../components/FormComponents/Inputs/BorderedMaskedInput';
import {
  formatCurrency,
  maskToCurrency,
  stringMaskedToNumber
} from '../../../utils/currencyHelper';
import ImageUploader from './ImageUploader';
import { useState } from 'react';
import { useStore } from '../../../store/useStore';
import { useEffect } from 'react';
import { CloseIcon } from '../../../assets/imgs';
import Input from '../../../components/FormComponents/Inputs/Input';
import FileUploader from '../../BillingLink/components/FileUploader';
import RadioButton from '../../../components/FormComponents/RadioButton/RadioButton';

export default function AddStoreProductModal() {
  const [form, setForm] = useState({ file: '', image: '' });

  const product = useStore((state) => state.products.selectedProduct);
  const createPublicProduct = useStore((state) => state.products.createPublicProduct);
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.products.isLoading);
  const isCreatePublicProductVisible = useStore(
    (state) => state.products.isCreatePublicProductVisible
  );
  const toggleIsCreatePublicProductVisible = useStore(
    (state) => state.products.toggleIsCreatePublicProductVisible
  );
  const initial_custom_field = { key: '', value: '' };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';
  return (
    <Modal
      title={'Criar produto público'}
      show={isCreatePublicProductVisible}
      onClose={toggleIsCreatePublicProductVisible}
      noDefaultHeight>
      <Formik
        initialValues={{
          name: '',
          description: '',
          price: '0',
          custom: [initial_custom_field],
          hasCustomFields: false,
          hasAttachments: false
        }}
        validationSchema={Yup.object({
          name: Yup.string(),
          description: Yup.string(),
          price: Yup.string(),
          custom: Yup.array(),
          hasCustomFields: Yup.boolean(),
          hasAttachments: Yup.boolean()
        })}
        onSubmit={(values) => {
          console.log(values);
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('description', values.description);
          formData.append('price', stringMaskedToNumber(values.price));
          formData.append('custom_fields', JSON.stringify(values.custom));
          formData.append('image_url', form.image);
          formData.append('assets', form.file);
          formData.append('company_reference', company.id);
          formData.append('parcel', 1);
          if (product.item) formData.append('object_reference', product.item.product_id);
          createPublicProduct(formData);
        }}>
        {(formik) => {
          useEffect(() => {
            if (product) {
              formik.setValues({
                ...formik.values,
                ...product,
                price: formatCurrency(product.price)
              });
            }
          }, [product]);
          return (
            <form
              onSubmit={formik.handleSubmit}
              className={`grid h-full overflow-hidden ${
                isCreatePublicProductVisible ? 'block' : 'hidden'
              }`}>
              <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
                <div className="flex-1 flex gap-3">
                  <BorderedInput
                    placeholder="Introduza o nome"
                    type="text"
                    label="Produto"
                    error={errorMessage(formik, 'name')}
                    {...formik.getFieldProps('name')}
                  />
                  <BorderedMaskedInput
                    placeholder="0,00"
                    prefix="MZN"
                    type="text"
                    label="Preço"
                    mask="99999999999999"
                    beforeMaskedStateChange={maskToCurrency}
                    alwaysShowMask={false}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'price')}
                    {...formik.getFieldProps('price')}
                  />
                </div>
                <BorderedInput
                  placeholder="Crie seu texto de venda"
                  textarea
                  type="text"
                  label="Texto de venda"
                  error={errorMessage(formik, 'description')}
                  {...formik.getFieldProps('description')}
                />
                <ImageUploader setSelectedImage={setForm} />
                <div className="flex gap-2">
                  <RadioButton
                    label="Campos customizados"
                    checked={formik.values.hasCustomFields}
                    onClick={() => {
                      formik.setFieldValue('hasCustomFields', !formik.values.hasCustomFields);
                    }}
                  />
                  <RadioButton
                    label="Anexos"
                    checked={formik.values.hasAttachments}
                    onClick={() => {
                      formik.setFieldValue('hasAttachments', !formik.values.hasAttachments);
                    }}
                  />
                </div>
                {formik.values.hasCustomFields && (
                  <FieldArray name="custom">
                    {({ push, remove }) => (
                      <div>
                        {formik.values.custom.map((_, index) => (
                          <div
                            key={'custom-' + index}
                            className="border p-3 mb-5 border-gray-2 relative">
                            {index > 0 ? (
                              <div
                                className="absolute right-2 cursor-pointer"
                                onClick={() => remove(index)}>
                                <CloseIcon width={15} />
                              </div>
                            ) : null}
                            <div className="flex gap-5">
                              <Input
                                placeholder="Digite o título"
                                type="text"
                                label="Introduza o título"
                                error={errorMessage(formik, {
                                  topic: 'custom',
                                  index,
                                  field: 'key'
                                })}
                                {...formik.getFieldProps(`custom[${index}].key`)}
                              />
                              <Input
                                placeholder="Digite o valor"
                                type="text"
                                label="Introduza o valor"
                                error={errorMessage(formik, {
                                  topic: 'custom',
                                  index,
                                  field: 'value'
                                })}
                                {...formik.getFieldProps(`custom[${index}].value`)}
                              />
                            </div>
                          </div>
                        ))}
                        <div className="text-center">
                          <button
                            type="button"
                            className="underline underline-offset-4 text-orange"
                            onClick={() => push(initial_custom_field)}>
                            Adicionar campo customizado
                          </button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                )}
                {formik.values.hasAttachments && <FileUploader setForm={setForm} form={form} />}
              </div>
              <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
                <FilledButton
                  onClick={toggleIsCreatePublicProductVisible}
                  bg={Colors.Gray1}
                  color={Colors.Orange}>
                  Cancelar
                </FilledButton>
                <FilledButton type="submit" loading={isLoading}>
                  Avançar
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
