import produce from 'immer';
import { toast } from 'react-toastify';
import companyService from '../../services/companyService';
import {
  formatFormOptions,
  formatGetCompany,
  formatPaymentMethods,
  formatUpdateCompany
} from '../../services/serviceFormatters';
import { decodeJwt } from '../../utils/functions';
import { ADMIN } from '../../utils/permissions';

export const createCompanySlice = (set, get) => ({
  company: {
    showAddReceptionModal: false,
    showVerifyAccountModal: false,
    showCreateUserModal: false,
    showDeleteUserModal: false,
    showUserModal: false,

    isLoading: false,

    company: {
      package: null,
      isVerified: true,
      permissions: decodeJwt(localStorage.getItem('token'))?.permissions ?? []
    },
    paymentMethods: null,
    companyTypesAvaiable: [],
    users: [],
    user: {},

    toggleShowVerifyAccountModal: () => {
      set(
        produce((state) => {
          state.company.showVerifyAccountModal = !state.company.showVerifyAccountModal;
        })
      );
    },
    toggleShowUserModal: (user = {}) => {
      set(
        produce((state) => {
          state.company.showUserModal = !state.company.showUserModal;
          state.company.user = user;
        })
      );
    },
    toggleDeleteUserModal: (user = {}) => {
      set(
        produce((state) => {
          state.company.showDeleteUserModal = !state.company.showDeleteUserModal;
          state.company.user = user;
        })
      );
    },
    toogleReceptionModal: () => {
      set(
        produce((state) => {
          state.company.showAddReceptionModal = !state.company.showAddReceptionModal;
        })
      );
    },
    toggleCreateUserModal: () => {
      set(
        produce((state) => {
          state.company.showCreateUserModal = !state.company.showCreateUserModal;
        })
      );
    },
    updateReceivePaymentMethods: (paymentMethods) => {
      companyService
        .updatePaymentMethods(formatPaymentMethods(paymentMethods))
        .then(() => {
          toast.success('Métodos de recebimento atualizados com sucesso');
          get().company.toogleReceptionModal();
          get().company.getPaymentMethods();
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    },
    updateCompany: (companyData) => {
      set(
        produce((state) => {
          state.company.isLoading = true;
        })
      );

      companyService
        .updateCompany(formatUpdateCompany(companyData))
        .then(() => {
          get().company.getCompany();
          toast.success('Dados da empresa atualizados com sucesso');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoading = false;
            })
          );
        });
    },
    getCompany: () => {
      set(
        produce((state) => {
          state.company.isLoading = true;
        })
      );
      companyService
        .getCompanyResume()
        .then((companyData) => {
          const newCompany = formatGetCompany(companyData);
          set(
            produce((state) => {
              state.company.company = {
                ...get().company.company,
                ...newCompany.company_data
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoading = false;
            })
          );
        });
    },
    getCompanyUsers: () => {
      set(
        produce((state) => {
          state.company.isLoading = true;
        })
      );
      companyService
        .getCompanyUsers()
        .then((users) => {
          set(
            produce((state) => {
              state.company.users = users;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoading = false;
            })
          );
        });
    },
    cleanCompanyUsers: () => {
      set(
        produce((state) => {
          state.company.users = [];
        })
      );
    },
    getPaymentMethods: () => {
      set(
        produce((state) => {
          state.company.isLoading = true;
        })
      );
      companyService
        .getPaymentMethods()
        .then((paymentMethods) => {
          set(
            produce((state) => {
              state.company.paymentMethods = paymentMethods;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoading = false;
            })
          );
        });
    },
    getCompanyResume: () => {
      set(
        produce((state) => {
          state.company.isLoading = true;
        })
      );
      companyService
        .getCompanyResume()
        .then((companyData) => {
          const { credit, is_verified: isVerified, wallet } = companyData;
          const { permissions = [], is_company } = decodeJwt(localStorage.getItem('token'));
          set(
            produce((state) => {
              state.company.company = {
                ...state.company.company,
                ...companyData,
                ...credit,
                isVerified,
                wallet,
                permissions: is_company ? ADMIN : permissions
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.company.isLoading = false;
            })
          );
        });
    },
    getCompanyTypesAvaiable: () => {
      companyService
        .getCompanyTypes()
        .then((companyTypes) => {
          set(
            produce((state) => {
              state.company.companyTypesAvaiable = formatFormOptions(companyTypes, 'type');
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.company.companyTypesAvaiable = [];
            })
          );
        });
    }
  }
});
