import Modal from '../../../components/FormComponents/Modal/Modal';
import { useStore } from '../../../store/useStore';
import Permissions from '../../Permissions/components/Permissions';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Colors } from '../../../utils/colors';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { PERMISSIONS } from '../../../utils/permissions';

export default function CreateUserModal() {
  const [checked, setChecked] = useState([]);
  const showCreateUserModal = useStore((state) => state.company.showCreateUserModal);
  const toggleCreateUserModal = useStore((state) => state.company.toggleCreateUserModal);

  const isLoading = useStore((state) => state.auth.isLoading);
  const createUser = useStore((state) => state.auth.createUser);

  return (
    <Modal title={'Adicionar usuário'} show={showCreateUserModal} onClose={toggleCreateUserModal}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().required('Campo obrigatório'),
          password: Yup.string()
        })}
        onSubmit={(values) => {
          if (checked.length === 0) {
            toast.info('Selecione pelo menos uma permissão');
            return;
          }
          createUser({ ...values, permissions: checked });
        }}>
        {(formik) => (
          <div className="overflow-y-auto p-5">
            <div className="space-y-4 font-medium text-gray-6">
              <form>
                <div>
                  <label htmlFor="email">
                    Email <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    placeholder="Insira o email do novo usuário"
                    className="w-full border-2 border-gray-2 py-2 px-3 rounded-[5px] focus:outline-none"
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-red">{formik.errors.email}</p>
                  ) : null}
                </div>

                <div className="my-4">
                  <label htmlFor="password">
                    Palavra-passe <span className="text-red">*</span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Insira a palavra-passe do novo usuário"
                    autoComplete="off"
                    className="w-full border-2 border-gray-2 py-2 px-3 rounded-[5px] focus:outline-none"
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-red">{formik.errors.password}</p>
                  ) : null}
                </div>
                <p>
                  <span className="text-red">*</span> Campos de preenchimento obrigatório.
                </p>
              </form>
            </div>
            <Permissions
              initialState={PERMISSIONS}
              setChecked={setChecked}
              text={'Selecione permissões'}
            />
            <div className="flex justify-around mt-5">
              <FilledButton
                className="px-10"
                onClick={toggleCreateUserModal}
                bg={Colors.Gray1}
                color={Colors.black}>
                Cancelar
              </FilledButton>
              <FilledButton
                onClick={() => formik.handleSubmit()}
                loading={isLoading}
                className="px-10"
                bg={Colors.Green}>
                Adicionar usuário
              </FilledButton>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
}
