export function ConditionalComponent({ condition, children, opposite }) {
  return condition ? children : opposite ?? null;
}

const experimentalCustomers = [
  '461f8213-7cdd-48a5-9bbc-cc32f5a52314',
  '71e61013-18d6-480a-bbd4-18d564060420',
  '99ec7723-41bc-4524-b9b6-8832caef53a6',
  '6524ef52-9b96-4607-8cc1-a0c3dda8ffbc',
  'fc2e3681-db36-4bd4-989b-731a8d3635b3',
  'f642377d-f1f9-47b1-a0ac-5dd42fcf2801',
  'c05678d8-a19c-42f0-adf1-903168e6b673'
];
export function isExperimentalCustomer(id) {
  return experimentalCustomers.includes(id);
}
