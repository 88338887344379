import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
// import RoutesAggregator from './components/RoutesAggregator';
import CompanySection from './components/CompanySection';
import { useStore } from '../../store/useStore';
import ReceptionSection from './components/ReceptionSection';
import PlanSection from './components/PlanSection';
import Skeleton from 'react-loading-skeleton';
import AddReceptionModal from './components/AddReceptionModal';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../utils/colors';
import DeleteAccountModal from './components/DeleteAccountModal';
import UsersSection from './components/UsersSection';
import CreateUserModal from './components/CreateUserModal';
import { Protected } from '../../components/Protected/Protected';
import DeleteUserModal from './components/DeleteUserModal';
import UserModal from './components/UserModal';
export function Configurations() {
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.company.isLoading);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleModalOpen = () => {
    setIsModalOpen((modal) => !modal);
  };

  return (
    <div>
      <div>
        <PageHeader title="Configurações" />
        <div className="text-xl font-medium pb-5 flex gap-3">
          {isLoading ? <Skeleton width={200} /> : <div>{company?.name}</div>}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        <div className="md:w-[40%]">
          <CompanySection />
        </div>
        <div className="flex flex-col gap-5 md:w-[60%]">
          <ReceptionSection />
          <Protected necessaryPermissions={['platform:*']}>
            <UsersSection />
          </Protected>
          <div className="md:w-fit">
            <PlanSection />
          </div>
        </div>
      </div>
      <Protected necessaryPermissions={['configurations:update']}>
        <AddReceptionModal />
      </Protected>
      <DeleteUserModal />
      <CreateUserModal />
      <UserModal />
      <Protected necessaryPermissions={['platform:*']}>
        <FilledButton onClick={handleModalOpen} className="px-10 mt-5" bg={Colors.Red}>
          Apagar conta
        </FilledButton>
        <DeleteAccountModal isModalOpen={isModalOpen} handleModalOpen={handleModalOpen} />
      </Protected>
    </div>
  );
}

// export default function MyAccount() {
//   return (
//     <div className="">
//       <PageHeader title="Minha conta" />
//       <RoutesAggregator />
//     </div>
//   );
// }
