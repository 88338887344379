const BILLINGS = {
  ALL: 'billings:*',
  CREATE: 'billings:create',
  READ: 'billings:read',
  UPDATE: 'billings:update',
  DELETE: 'billings:delete'
};

const USERS = {
  ALL: 'users:*',
  CREATE: 'users:create',
  READ: 'users:read',
  DELETE: 'users:delete',
  UPDATE: 'users:update'
};

const CUSTOMERS = {
  ALL: 'customers:*',
  CREATE: 'customers:create',
  READ: 'customers:read',
  UPDATE: 'customers:update',
  DELETE: 'customers:delete'
};

const REPORTS = {
  ALL: 'reports:*',
  READ: 'reports:read',
  DOWNLOAD: 'reports:download'
};

const PRODUCTS = {
  ALL: 'products:*',
  READ: 'products:read',
  CREATE: 'products:create',
  UPDATE: 'products:update',
  DELETE: 'products:delete',
  PUBLISH: 'products:publish',
  DELETE_PUBLIC: 'products:delete_public'
};

const CONFIGURATIONS = {
  ALL: 'configurations:*',
  UPDATE: 'configurations:update',
  READ: 'configurations:read'
};

const ADMIN = [
  'platform:*',
  ...Object.values(BILLINGS),
  ...Object.values(CUSTOMERS),
  ...Object.values(REPORTS),
  ...Object.values(PRODUCTS),
  ...Object.values(USERS),
  ...Object.values(CONFIGURATIONS)
];

const PERMISSIONS = [
  {
    name: 'Cobrança',
    permission: BILLINGS.ALL,
    password: 'Controle total de cobranças e faturas',
    checked: false,
    subPermissions: [
      {
        name: 'Visualizar cobranças',
        permission: BILLINGS.READ,
        description: 'Visualizar faturas de cobrança',
        checked: false
      },
      {
        name: 'Criar cobranças',
        permission: BILLINGS.CREATE,
        description: 'Criar novas cobranças',
        checked: false,
        requiredPermission: BILLINGS.READ
      },
      {
        name: 'Editar cobranças',
        permission: BILLINGS.UPDATE,
        description: 'Editar faturas de cobrança',
        checked: false,
        requiredPermission: BILLINGS.CREATE
      },
      {
        name: 'Excluir cobranças',
        permission: BILLINGS.DELETE,
        description: 'Excluir faturas de cobrança',
        checked: false,
        requiredPermission: BILLINGS.UPDATE
      }
    ]
  },
  {
    name: 'Gestão de clientes',
    permission: CUSTOMERS.ALL,
    description: 'Controle total da gestão de clientes',
    checked: false,
    subPermissions: [
      {
        name: 'Criar clientes',
        permission: CUSTOMERS.CREATE,
        description: 'Criar novos clientes',
        checked: false,
        requiredPermission: CUSTOMERS.READ
      },
      {
        name: 'Visualizar clientes',
        permission: CUSTOMERS.READ,
        description: 'Visualizar detalhes dos clientes',
        checked: false
      },
      {
        name: 'Editar clientes',
        permission: CUSTOMERS.UPDATE,
        description: 'Editar informações dos clientes',
        checked: false,
        requiredPermission: CUSTOMERS.CREATE
      },
      {
        name: 'Excluir clientes',
        permission: CUSTOMERS.DELETE,
        description: 'Excluir registros de clientes',
        checked: false,
        requiredPermission: CUSTOMERS.UPDATE
      }
    ]
  },
  {
    name: 'Relatórios',
    permission: REPORTS.ALL,
    description: 'Gerar relatórios financeiros',
    checked: false,
    subPermissions: [
      {
        name: 'Visualizar relatórios',
        permission: REPORTS.READ,
        description: 'Visualizar relatórios financeiros',
        checked: false
      },
      {
        name: 'Baixar relatórios',
        permission: REPORTS.DOWNLOAD,
        description: 'Baixar relatórios em XSLX',
        checked: false,
        requiredPermission: REPORTS.READ
      }
    ]
  },
  {
    name: 'Gestão de usuários',
    permission: USERS.ALL,
    description: 'Controle total dos usuários da plataforma',
    checked: false,
    subPermissions: [
      {
        name: 'Criar usuários',
        permission: USERS.CREATE,
        description: 'Criar novos usuários',
        checked: false,
        requiredPermission: USERS.READ
      },
      {
        name: 'Visualizar usuários',
        permission: USERS.READ,
        description: 'Visualizar perfis de usuários',
        checked: false
      },
      {
        name: 'Editar usuários',
        permission: USERS.UPDATE,
        description: 'Editar papéis e permissões de usuários',
        checked: false,
        requiredPermission: USERS.READ
      },
      {
        name: 'Excluir usuários',
        permission: USERS.DELETE,
        description: 'Excluir usuários',
        checked: false,
        requiredPermission: USERS.READ
      }
    ]
  },
  {
    name: 'Configurações',
    permission: CONFIGURATIONS.ALL,
    description: 'Gerenciar configurações da plataforma',
    checked: false,
    subPermissions: [
      {
        name: 'Alterar configurações',
        permission: CONFIGURATIONS.UPDATE,
        description: 'Editar configurações da empresa',
        checked: false,
        requiredPermission: CONFIGURATIONS.READ
      },
      {
        name: 'Visualizar configurações',
        permission: CONFIGURATIONS.READ,
        description: 'Visualizar configurações atuais da empresa',
        checked: false
      }
    ]
  },
  {
    name: 'Produtos',
    permission: PRODUCTS.ALL,
    description: 'Controle total sobre produtos',
    checked: false,
    subPermissions: [
      {
        name: 'Visualizar produtos',
        permission: PRODUCTS.READ,
        description: 'Visualizar produtos',
        checked: false
      },
      {
        name: 'Criar produtos',
        permission: PRODUCTS.CREATE,
        description: 'Criar novos produtos',
        checked: false,
        requiredPermission: PRODUCTS.READ
      },
      {
        name: 'Editar produtos',
        permission: PRODUCTS.UPDATE,
        description: 'Editar produtos existentes',
        checked: false,
        requiredPermission: PRODUCTS.READ
      },
      {
        name: 'Excluir produtos',
        permission: PRODUCTS.DELETE,
        description: 'Excluir produtos',
        checked: false,
        requiredPermission: PRODUCTS.READ
      },
      {
        name: 'Publicar produtos',
        permission: PRODUCTS.PUBLISH,
        description: 'Publicar produtos',
        checked: false,
        requiredPermission: PRODUCTS.CREATE
      },
      {
        name: 'Remover produtos públicos',
        permission: PRODUCTS.DELETE_PUBLIC,
        description: 'Remover produtos publicados',
        checked: false,
        requiredPermission: PRODUCTS.DELETE
      }
    ]
  }
];
export { BILLINGS, CUSTOMERS, REPORTS, PRODUCTS, USERS, CONFIGURATIONS, ADMIN, PERMISSIONS };
// Aplicar verificação em múltiplos níveis:
// - Rotas, componentes, requisições, estados
