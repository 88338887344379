import { useState, useEffect } from 'react';

export default function Permissions({ setChecked, field = 'permissions', initialState, text }) {
  const [permissions, setPermissions] = useState(initialState);

  useEffect(() => {
    if (!Array.isArray(initialState)) {
      setChecked((state) => {
        return { ...state, [field]: getCheckedPermissions(permissions) };
      });
    }
    setChecked(getCheckedPermissions(permissions));
  }, [permissions]);

  const handleCheck = (ev, isSubPermission = false, parentPermissionName = null) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (isSubPermission) {
          if (permission.permission !== parentPermissionName) return permission;

          const updatedSubPermissions = permission.subPermissions.map((subPermission) => {
            if (subPermission.permission === ev.target.id) {
              if (subPermission.requiredPermission) {
                const requiredPermissionChecked = permission.subPermissions.find(
                  (p) => p.permission === subPermission.requiredPermission
                )?.checked;
                if (!requiredPermissionChecked) {
                  // Não permite marcar se a permissão requerida não estiver marcada
                  return subPermission;
                }
              }
              return { ...subPermission, checked: !subPermission.checked };
            }
            return subPermission;
          });

          return { ...permission, subPermissions: updatedSubPermissions };
        } else {
          if (permission.permission !== ev.target.id) return permission;

          const updatedSubPermissions = permission.subPermissions?.map((subPermission) => ({
            ...subPermission,
            checked:
              !permission.checked && (!subPermission.requiredPermission || permission.checked)
          }));

          return {
            ...permission,
            checked: !permission.checked,
            subPermissions: updatedSubPermissions || permission.subPermissions
          };
        }
      })
    );
  };

  return (
    <div className="w-full max-w-3xl p-6 text-gray-700 ">
      <h2 className="text-2xl font-semibold mb-2">{text}</h2>
      <div className="space-y-4">
        {permissions.map((permission) => (
          <div key={permission.name} className="space-y-2">
            <div className="flex items-start">
              <input
                type={'checkbox'}
                id={permission.permission}
                className="mt-1 bg-gray-700 border-gray-600"
                onChange={handleCheck}
                checked={permission.checked}
              />
              <div className="ml-3">
                <label htmlFor={permission.name} className="font-medium">
                  {permission.name}
                </label>
                <p className="text-sm text-gray-400">{permission.description}</p>
              </div>
            </div>
            {permission.subPermissions && (
              <div className="ml-6 space-y-2">
                {permission.subPermissions.map((subPermission) => (
                  <div key={subPermission.name} className="flex items-start">
                    <input
                      type={'checkbox'}
                      id={subPermission.permission}
                      className="mt-1 bg-gray-700 border-gray-600"
                      checked={subPermission.checked}
                      onChange={(ev) => handleCheck(ev, true, permission.permission)}
                      disabled={
                        subPermission.requiredPermission &&
                        !permission.subPermissions.find(
                          (p) => p.permission === subPermission.requiredPermission
                        )?.checked
                      }
                    />
                    <div className="ml-3">
                      <label htmlFor={subPermission.name} className="font-medium">
                        {subPermission.name}
                      </label>
                      <p className="text-sm text-gray-400">{subPermission.description}</p>
                      {subPermission.requiredPermission && (
                        <p className="text-sm text-yellow-500">
                          Requer:{' '}
                          {
                            permission.subPermissions.find(
                              (p) => p.permission === subPermission.requiredPermission
                            )?.name
                          }
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function getCheckedPermissions(permissions) {
  return permissions.reduce((checkedPermissions, permission) => {
    if (permission.checked) {
      checkedPermissions.push(permission.permission);
    }

    if (permission.subPermissions) {
      checkedPermissions.push(
        ...permission.subPermissions
          .filter((subPermission) => subPermission.checked)
          .map((subPermission) => subPermission.permission)
      );
    }

    return checkedPermissions;
  }, []);
}
