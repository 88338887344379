import React, { useEffect } from 'react';
import { StatusIcon } from '../../../assets/imgs';
import PersonIcon from '../../../assets/imgs/PersonIcon';
import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import { formatDateToText } from '../../../utils/dateHelpers';
import {
  BILLING_STATUS_COLOR,
  CUSTOMER_STATUS_COLOR,
  BILLING_TYPES_LABELS,
  BILLING_STATUSES_LABELS
} from '../../../utils/constants';
import { formatCurrency } from '../../../utils/currencyHelper';
import useResponsivity from '../../../hooks/useResponsivity';
import { useNavigate } from 'react-router-dom';
import MoreActionBilling from '../../Billing/components/MoreActionBilling';
import { useStore } from '../../../store/useStore';
import AdvancedFiltering from './AdvancedFiltering';

export default function BillingsTable({ data, loading = false }) {
  const { isMobile } = useResponsivity();
  const hiddenColumns = isMobile ? ['status', 'due_date', 'payment_type', 'type'] : [];
  const navigate = useNavigate();
  const selectBillings = useStore((state) => state.billings.selectBillings);
  const selectedBillings = useStore((state) => state.billings.selectedBillings);
  const resetBillingsSelected = useStore((state) => state.billings.resetBillingsSelected);

  const onClickRow = (row) => {
    navigate('/cobrancas/' + row.original.id);
  };

  const onSelectRow = (billings, checked) => {
    selectBillings(billings, checked);
  };

  useEffect(() => {
    return () => {
      resetBillingsSelected();
    };
  }, []);

  const columns = [
    {
      Header: 'Nome',
      accessor: 'customer.name', // accessor is the "key" in the data
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <div className="!w-6">
            <PersonIcon
              width={24}
              height={24}
              color={CUSTOMER_STATUS_COLOR[row.original.customer.customer_situation]}
            />
          </div>
          {row.original.customer.first_name} {row.original.customer.last_name}
        </div>
      )
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => (
        <div>
          <div className="text-xl">MZN {formatCurrency(row.original.value)}</div>
          {row.original.total_value ? (
            <div className="text-sm">(Total MZN {formatCurrency(row.original?.total_value)})</div>
          ) : null}
        </div>
      )
    },
    {
      Header: 'Tipo de cobrança',
      accessor: 'type',
      Cell: ({ row }) => (
        <div>
          <div className="text-base">{BILLING_TYPES_LABELS[row.original.type]}</div>
          {row.original.actual_installment && row.original.total_installments ? (
            <div className="text-sm">
              ({row.original?.actual_installment}/{row.original?.total_installments})
            </div>
          ) : null}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <StatusIcon color={BILLING_STATUS_COLOR[row.original.status]} />
          <div style={{ color: BILLING_STATUS_COLOR[row.original.status] }}>
            {BILLING_STATUSES_LABELS[row.original.status]}
          </div>
        </div>
      )
    },
    {
      Header: 'Prazo',
      accessor: 'due_date',
      Cell: ({ row }) => (
        <div>
          <div>{formatDateToText(new Date(row.original.due_date))}</div>
        </div>
      )
    },
    {
      Header: 'Data de Pagamento',
      accessor: 'paidWhen',
      Cell: ({ row }) => (
        <div>
          <div className="text-base">
            {row.original?.paidWhen ? formatDateToText(new Date(row.original.paidWhen)) : ''}
          </div>
        </div>
      )
    },
    {
      accessor: 'action',
      Cell: ({ row }) => (
        <div>
          <MoreActionBilling billing={row.original} asDots />
        </div>
      )
    }
  ];

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        onSelectRow={onSelectRow}
        data={data}
        hiddenColumns={hiddenColumns}
        onClickRow={onClickRow}
        selectedRows={selectedBillings}
      />
      <div className="mt-8 flex justify-end">
        <AdvancedFiltering />
      </div>
    </>
  );
}
