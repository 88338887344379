import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Navbar from './components/Navbar/Navbar';
import MyCredits from './pages/MyCredits/MyCredits';
import Billings from './pages/Billings/Billings';
import Customers from './pages/Customers/Customers';
import { Configurations } from './pages/Configurations/Configurations';
import CreateBillingModal from './components/CreateBillingModal/CreateBillingModal';
import { useStore } from './store/useStore';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import NavbarMobile from './components/Navbar/NavbarMobile';
import useNavbarHovered from './hooks/useNavbarHovered';
import useResponsivity from './hooks/useResponsivity';
import BillingLink from './pages/BillingLink/BillingLink';
import 'moment/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Customer from './pages/Customer/Customer';
import Billing from './pages/Billing/Billing';
import Invoice from './pages/Invoice/Invoice';
import OtpVerification from './pages/OtpVerification/OtpVerification';
import Group from './pages/Group/Group';
import AccountRecovery from './pages/AccountRecovery/AccountRecovery';
import NotFound from './pages/NotFound/NotFound';
import VerifyAccount from './components/CreateBillingModal/VerifyAccount';
import React from 'react';
import Products from './pages/Products/Products';
import Store from './pages/Store/Store';
import { Protected } from './components/Protected/Protected';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');
moment.locale('pt');

const ProtectedRoute = ({ redirectPath = '/' }) => {
  const company = useStore((state) => state.company.company);
  const showCreateBillingModal = useStore((state) => state.billings.showCreateBillingModal);
  const showNavbar = useStore((state) => state.navbar.showNavbar);
  const setShowNavbar = useStore((state) => state.navbar.setShowNavbar);
  const toggleShowVerifyAccountModal = useStore(
    (state) => state.company.toggleShowVerifyAccountModal
  );
  const showVerifyAccountModal = useStore((state) => state.company.showVerifyAccountModal);

  const isNavbarHovered = useNavbarHovered();
  const { isMobile } = useResponsivity();

  if (!localStorage.getItem('token')) {
    return <Navigate to={redirectPath} replace />;
  }

  const closeNavbar = () => {
    setShowNavbar(false);
  };

  const handleOnBackDropClick = (e) => {
    if (e.target.id === 'backdrop') closeNavbar();
  };

  return (
    <div className="flex flex-col md:flex-row relative">
      {!company?.isVerified && (
        <div className="md:block hidden absolute top-0 left-0 right-0 p-2 bg-orange text-white text-center">
          Verifique o seu email para extrair o máximo da Piminder.
          <button onClick={toggleShowVerifyAccountModal} className="underline ml-2">
            Confirmar email
          </button>
        </div>
      )}
      <Navbar />
      <NavbarMobile />
      <div
        className={`md:ml-[60px] 2xl:ml-[280px] py-6 md:py-14 px-6 md:px-12 w-[100%] ${
          showNavbar ? '' : 'md:ml-[0px]'
        }`}>
        <Outlet />
      </div>
      {showNavbar && isMobile ? (
        <div
          id="backdrop"
          onClick={handleOnBackDropClick}
          className={`${
            isNavbarHovered && 'hidden'
          } bg-black bg-opacity-50  inset-0 flex items-center justify-center fixed w-full z-30`}></div>
      ) : null}
      <Protected necessaryPermissions={['billings:create']}>
        <CreateBillingModal show={showCreateBillingModal} />
      </Protected>
      {showVerifyAccountModal && <VerifyAccount />}
    </div>
  );
};

export default function App() {
  return (
    <div className="text-black">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recuperar-conta" element={<AccountRecovery />} />
        <Route path="/cadastro" element={<Register />} />
        <Route path="/esqueceu-senha" element={<ForgotPassword />} />
        <Route path="/cobrancas/link/:linkId" element={<BillingLink />} />
        <Route path="confirmacao_email" element={<OtpVerification />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/clientes"
            element={
              <Protected route={true} necessaryPermissions={['customers:read']}>
                <Customers />
              </Protected>
            }
          />
          <Route
            path="/clientes/:id"
            element={
              <Protected route={true} necessaryPermissions={['customers:read']}>
                <Customer />
              </Protected>
            }
          />
          <Route
            path="/grupos"
            element={
              <Protected route={true} necessaryPermissions={['customers:read']}>
                <Group />
              </Protected>
            }
          />
          <Route
            path="/cobrancas"
            element={
              <Protected route={true} necessaryPermissions={['billings:read']}>
                <Billings />
              </Protected>
            }
          />
          <Route
            path="/cobrancas/:id"
            element={
              <Protected route={true} necessaryPermissions={['billings:read']}>
                <Billing />
              </Protected>
            }
          />
          <Route
            path="/parcelamentos/:id"
            element={
              <Protected route={true} necessaryPermissions={['billings:read']}>
                <Invoice />
              </Protected>
            }
          />
          <Route
            path="/meus-creditos"
            element={
              <Protected route={true} necessaryPermissions={['reports:read']}>
                <MyCredits />
              </Protected>
            }
          />
          <Route
            path="/meus-produtos"
            element={
              <Protected route={true} necessaryPermissions={['products:read']}>
                <Products />
              </Protected>
            }
          />
          <Route
            path="/loja"
            element={
              <Protected route={true} necessaryPermissions={['products:read']}>
                <Store />
              </Protected>
            }
          />
          <Route
            path="/configuracoes"
            element={
              <Protected route={true} necessaryPermissions={['configurations:read']}>
                <Configurations />
              </Protected>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}
