import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import BillingDataSection from './components/BillingDataSection';
import { useStore } from '../../store/useStore';
import NotificationsSection from './components/NotificationsSection';
import DeleteBillingModal from './components/DeleteBillingModal';
import CancelBillingReceptionModal from './components/CancelBillingReceptionModal';
import ConfirmBillingReceptionModal from './components/ConfirmBillingReceptionModal';
import Skeleton from 'react-loading-skeleton';
import LabelWrapper from '../../components/FormComponents/LabelWrapper/LabelWrapper';
import BorderlessStaticText from '../../components/FormComponents/Inputs/BorderlessStaticText';
import { Protected } from '../../components/Protected/Protected';
// import Pagination from '../../components/Pagination/Pagination';
// import { useParams } from 'react-router-dom';

export default function Billing() {
  const billing = useStore((state) => state.billings.billing);
  const isBillingLoading = useStore((state) => state.billings.isBillingLoading);

  // const params = useParams();

  // const notificationsCurrentPage = useStore((state) => state.billings.notificationsCurrentPage);
  // const notificationsTotalPages = useStore((state) => state.billings.notificationsTotalPages);
  // const changeNotificationsPage = useStore((state) => state.billings.changeNotificationsPage);

  // const handleChangeNotificationsPage = (page, makeRequest) => {
  //   changeNotificationsPage(params.id, page, makeRequest);
  // };
  const sendMethods = () => {
    if (billing?.notification_options) {
      const sendMethods = new Set();
      Object.values(billing.notification_options).forEach((option) => {
        Object.entries(option).forEach(([key, value]) => {
          if (!Number.isFinite(value) && value) sendMethods.add(key);
        });
      });
      return Array.from(sendMethods).join(' / ');
    }
  };

  return (
    <div>
      <div>
        <PageHeader title="Cobranças" />
        <div className="text-xl font-medium pb-5 flex gap-3">
          <span>Factura </span>
          {isBillingLoading ? <Skeleton width={50} /> : <span>{billing?.id}</span>}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        <div className="md:flex-1 space-y-5">
          <BillingDataSection />
          <LabelWrapper label="Método de envio">
            <BorderlessStaticText>{sendMethods()}</BorderlessStaticText>
          </LabelWrapper>
        </div>
        <div className="flex flex-col gap-5 md:flex-1">
          <NotificationsSection />

          {/* <NotSentNotificationsSection /> */}
          {/* <div className="flex justify-center">
            <Pagination
              currentPage={notificationsCurrentPage}
              totalPages={notificationsTotalPages}
              changePage={handleChangeNotificationsPage}
            />
          </div> */}
        </div>
      </div>
      <Protected necessaryPermissions={['billings:read', 'billings:delete']}>
        <DeleteBillingModal />
      </Protected>
      <Protected necessaryPermissions={['billings:update']}>
        <CancelBillingReceptionModal />
        <ConfirmBillingReceptionModal />
      </Protected>
    </div>
  );
}
