import React, { useEffect } from 'react';
import * as Icons from '../../assets/imgs';
import { useStore } from '../../store/useStore';
import NavbarCreateBillingButton from './components/NavbarCreateBillingButton';
import NavbarLink from './components/NavbarLink';
import NavbarLinkGroup from './components/NavbarLinkGroup';
import useNavbarHovered from '../../hooks/useNavbarHovered';
import useResponsivity from '../../hooks/useResponsivity';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Protected } from '../Protected/Protected';

export default function Navbar() {
  const openCreateBillingModal = useStore((state) => state.billings.openCreateBillingModal);
  const showNavbar = useStore((state) => state.navbar.showNavbar);
  const setShowNavbar = useStore((state) => state.navbar.setShowNavbar);
  const setNavbarHovered = useStore((state) => state.navbar.setNavbarHovered);
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.company.isLoading);

  const isNavbarHovered = useNavbarHovered();
  const { isMobile } = useResponsivity();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
  };

  useEffect(() => {
    if (isMobile) setShowNavbar(false);
    else setShowNavbar(true);
  }, [isMobile]);

  const setNavbarIsHovered = () => setNavbarHovered(true);
  const setNavbarIsNotHovered = () => setNavbarHovered(false);

  const goToConfiguration = () => navigate('/configuracoes');

  return (
    <div
      onMouseEnter={setNavbarIsHovered}
      onMouseLeave={setNavbarIsNotHovered}
      onClick={setNavbarIsHovered}
      className={`md:block h-full z-40 ${
        showNavbar
          ? `${isNavbarHovered ? 'w-[60px]' : 'w-[280px]'}  translate-x-[0px]`
          : 'translate-x-[-280px] w-[0px]'
      } bg-black min-h-screen rounded-tr-[30px] fixed text-white transition-all ease-in-out duration-700`}>
      <div>
        <div>
          <div className="flex pb-5 pr-10 pt-10 items-center md:overflow-hidden md:min-w-[200px]">
            <div className="pl-5 z-20 bg-black">
              <img width={20} src={Icons.PiminderLogo} />
            </div>
            <span
              className={`z-0 absolute ${
                !isNavbarHovered ? 'left-5 translate-x-[0px]' : ' translate-x-[-137px]'
              } font-semibold text-[32px] text-white transition-all ease-in-out duration-700`}>
              <img width={120} src={Icons.PiminderLargeLogo} />
            </span>
          </div>
          <div>
            <NavbarCreateBillingButton onClick={openCreateBillingModal} />
          </div>
          <div className="overflow-y-auto h-[calc(100vh-150px)] overflow-x-hidden">
            <div className="py-5 font-medium select-none bg-black overflow-hidden relative z-20">
              <NavbarLink title="Resumo" to="/dashboard" icon={Icons.ResumeIcon} hasActiveMark />
              <Protected necessaryPermissions={['customers:read']}>
                <NavbarLinkGroup
                  title="Clientes"
                  to="/clientes"
                  icon={Icons.GroupIcon}
                  showLinksTransitionClass="translate-y-[0px] h-[88px]"
                  hideLinksTransitionClass="translate-y-[-88px] h-[0px]">
                  <NavbarLink title="Clientes" to="/clientes" icon={Icons.PersonIcon} />
                  <NavbarLink title="Grupos" to="/grupos" icon={Icons.GroupIcon} />
                </NavbarLinkGroup>
              </Protected>
              <Protected necessaryPermissions={['billings:read']}>
                <NavbarLinkGroup
                  title="Cobranças"
                  to="/cobrancas"
                  extraPathsActive={['/parcelamentos']}
                  icon={Icons.BillingIcon}
                  showLinksTransitionClass="translate-y-[0px] h-[177px]"
                  hideLinksTransitionClass="translate-y-[-177px] h-[0px]">
                  <NavbarLink
                    title="Todas"
                    to="/cobrancas"
                    extraPathsActive={['/parcelamentos']}
                    icon={Icons.FilesIcon}
                  />
                  <NavbarLink
                    title="Avulsas"
                    to="/cobrancas"
                    extraPathsActive={['/parcelamentos']}
                    icon={Icons.FileIcon}
                  />
                  <NavbarLink
                    title="Parcelamentos"
                    to="/cobrancas"
                    extraPathsActive={['/parcelamentos']}
                    icon={Icons.InstallmentsIcon}
                  />
                  <NavbarLink
                    title="Assinaturas"
                    to="/cobrancas"
                    extraPathsActive={['/parcelamentos']}
                    icon={Icons.RefreshIcon}
                  />
                </NavbarLinkGroup>
              </Protected>
              <Protected necessaryPermissions={['reports:read']}>
                <NavbarLink
                  title="Carteira"
                  to="/meus-creditos"
                  icon={Icons.WalletIcon}
                  hasActiveMark
                />
              </Protected>
              <Protected necessaryPermissions={['products:read']}>
                <NavbarLinkGroup
                  title="Loja"
                  to="/meus-produtos"
                  icon={Icons.StoreIcon}
                  showLinksTransitionClass="translate-y-[0px] h-[88px]"
                  hideLinksTransitionClass="translate-y-[-88px] h-[0px]">
                  <NavbarLink title="Produtos" to="/meus-produtos" icon={Icons.BoxIcon} />
                  <NavbarLink title="Loja" to="/loja" icon={Icons.StoreIcon} />
                </NavbarLinkGroup>
              </Protected>
            </div>
            <div className="pb-5 flex flex-col gap-5">
              <hr
                className={`border-gray-4 transition-all ease-in-out duration-700 ${
                  !isNavbarHovered ? 'mx-5' : 'mx-2'
                }`}
              />
              <div
                className={`flex gap-5 px-5 pt-2 md:overflow-hidden md:min-w-[200px] items-center cursor-pointer`}
                onClick={goToConfiguration}>
                <img className="w-[25px] h-[25px]" src={Icons.GymGravatar} />
                <div className="font-medium">
                  {isLoading ? (
                    <Skeleton width={150} height={16} />
                  ) : (
                    <div>{company?.name ?? '-'}</div>
                  )}
                  {isLoading ? (
                    <Skeleton width={120} height={14} />
                  ) : (
                    <div className="text-sm">{company?.email ?? '-'}</div>
                  )}
                </div>
              </div>
              <div>
                <Protected necessaryPermissions={['configurations:read']}>
                  <NavbarLink
                    title="Configurações"
                    to="/configuracoes"
                    icon={Icons.ConfigIcon}
                    color="white"
                  />
                </Protected>
                <NavbarLink
                  onClick={logout}
                  title="Sair"
                  to="/"
                  icon={Icons.LogoutIcon}
                  color="white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
