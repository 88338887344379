import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DeleteIcon, PlusIcon } from '../../../assets/imgs';
import LinkIcon from '../../../assets/imgs/LinkIcon';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';
import { formatCurrency } from '../../../utils/currencyHelper';
import { Protected } from '../../../components/Protected/Protected';
export default function StoreProduct({ id, name, description, price, image_url }) {
  const deletePublicProduct = useStore((state) => state.products.deletePublicProduct);
  const onCopyClick = (id) => {
    navigator.clipboard.writeText(`https://store.piminder.com/purchase/${id}`);
    toast.info('Link de produto copiado');
  };
  return (
    <div className="h-80 bg-slate-200 overflow-hidden hover:bg-slate-300 transition-colors duration-300 rounded-lg relative">
      <div className="right-3 flex flex-col top-3 absolute space-y-3 bg-white p-2 rounded-xl z-10">
        <Protected necessaryPermissions={['products:delete_public']}>
          <button
            onClick={() => {
              deletePublicProduct(id);
            }}
            className="bg-black/10 hover:bg-black/30 transition-all duration-300 p-3 rounded-xl">
            <DeleteIcon color={Colors.Gray6} />
          </button>
        </Protected>
        <button
          onClick={() => {
            onCopyClick(id);
          }}
          className="bg-black/10 hover:bg-black/30 transition-all duration-300 p-3 rounded-xl">
          <LinkIcon color={Colors.Gray6} />
        </button>
      </div>
      <div className="h-[60%] rounded-b-lg overflow-hidden relative">
        <img src={image_url} className="object-cover absolute" />
      </div>
      <div className="p-4 ">
        <div className="flex justify-between items-center">
          <h3 className="tracking-tighter text-lg font-medium text-zinc-800">{name}</h3>
          <span className="tracking-tighter text-zinc-700">{formatCurrency(price)} MZN</span>
        </div>
        <p className="text-zinc-700 tracking-tight mt-2">
          {description.length > 62 ? `${description.substring(0, 70)}...` : description}
        </p>
      </div>
    </div>
  );
}

export function AddStoreProduct() {
  return (
    <Link
      to={'/meus-produtos'}
      className="h-80 bg-slate-200 hover:bg-slate-300 transition-colors cursor-pointer duration-300 rounded-lg flex items-center justify-center">
      <div className="">
        <div className="mx-auto w-fit">
          <PlusIcon color="#000" height={20} width={20} />
        </div>
        <span className="mt-2 block uppercase font-medium">Adicionar produto</span>
      </div>
    </Link>
  );
}

export function StoreProductLoader({ position = 1 }) {
  return (
    <div
      style={{ animationDelay: `${position * 100}ms` }}
      className="animate-pulse h-80 bg-slate-200 overflow-hidden hover:bg-slate-300 transition-colors duration-300 rounded-lg relative">
      <div className="right-3 flex flex-col top-3 absolute space-y-3">
        <button className="bg-black/10 hover:bg-black/30 transition-all duration-300 p-3 rounded-xl">
          <DeleteIcon color={Colors.Gray6} />
        </button>
        <button className="bg-black/10 hover:bg-black/30 transition-all duration-300 p-3 rounded-xl">
          <LinkIcon color={Colors.Gray6} />
        </button>
      </div>
      <div className="h-[60%] bg-black/20 rounded-b-lg overflow-hidden" />
      <div className="p-4 ">
        <div className="flex justify-between items-center">
          <span className="w-32 rounded-md h-5 bg-zinc-400" />
          <span className="text-zinc-700 flex items-center gap-2">
            <span className="block bg-zinc-400 w-16 rounded-md h-5" /> MZN
          </span>
        </div>
        <div className="mt-2 flex gap-2 flex-wrap">
          {[100, 25, 57, 10].map((percent) => (
            <span
              key={percent}
              className={`bg-zinc-400 rounded h-4`}
              style={{ width: `${percent}%` }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
