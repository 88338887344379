import { toast } from 'react-toastify';
import productService from '../../services/productService';
import { formatFormObjectOptions } from '../../services/serviceFormatters';
import produce from 'immer';

export const createProductsSlice = (set, get) => ({
  products: {
    isLoading: false,

    products: [],
    selectedProducts: [],
    selectedProduct: {},
    publicProducts: [],

    isCreateProductVisible: false,
    isUpdateProductVisible: false,
    isDeleteProductVisible: false,
    isCreatePublicProductVisible: false,

    filters: {
      search: '',
      page: 1,
      lastPage: 1
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.products.filters[filter] = value;
        })
      );
    },
    resetFilters: () => {
      set(
        produce((state) => {
          state.products.filters = { search: '', page: 1, lastPage: 1 };
        })
      );
    },
    changeProductPage: (page, makeRequest = true) => {
      set(
        produce((state) => {
          state.products.filters.page = page;
        })
      );
      if (makeRequest) get().products.getProducts();
    },
    toggleIsCreateProductVisible: () => {
      set(
        produce((state) => {
          state.products.isCreateProductVisible = !state.products.isCreateProductVisible;
        })
      );
    },
    toggleIsCreatePublicProductVisible: (product = {}) => {
      set(
        produce((state) => {
          state.products.selectedProduct = product;
          state.products.isCreatePublicProductVisible =
            !state.products.isCreatePublicProductVisible;
        })
      );
    },
    toggleUpdateProductModal: (product = {}) => {
      set(
        produce((state) => {
          state.products.selectedProduct = product;
          state.products.isUpdateProductVisible = !state.products.isUpdateProductVisible;
        })
      );
    },
    toggleDeleteProductModal: (product = {}) => {
      set(
        produce((state) => {
          state.products.selectedProduct = product;
          state.products.isDeleteProductVisible = !state.products.isDeleteProductVisible;
        })
      );
    },
    getProductOptions: async (filters) => {
      try {
        const productsData = await productService.getProductOptions(filters);
        return formatFormObjectOptions(productsData, 'name');
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    },
    getProducts: async () => {
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .getProducts({ ...get().products.filters })
        .then((products) => {
          set(
            produce((state) => {
              state.products.products = products.data;
              state.products.filters.page = products.page;
              state.products.filters.lastPage = products.lastPage;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          set(
            produce((state) => {
              state.products.products = [];
              state.products.filters.page = 1;
              state.products.filters.lastPage = 1;
            })
          );
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    getPublicProducts: () => {
      const company = get().company.company;
      if (!company.id) return;
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .getPublicProducts(company.id)
        .then((data) => {
          set(
            produce((state) => {
              state.products.publicProducts = data;
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.products.publicProducts = [];
            })
          );
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    selectProducts: (products, checked) => {
      set(
        produce((state) => {
          const selectedProducts = get().products.selectedProducts;
          let newSelectedProducts;

          if (checked) {
            // Add the entire array of customers, eliminating duplicates based on unique ID
            const uniqueCustomerIds = new Set([
              ...selectedProducts.map((c) => c.id),
              ...products.map((c) => c.id)
            ]);
            newSelectedProducts = Array.from(uniqueCustomerIds).map((id) =>
              [...selectedProducts, ...products].find((c) => c.id === id)
            );
            // newSelectedProducts = [...selectedProducts, ...newSelectedProducts];
          } else {
            // Remove the entire array of customers based on unique ID
            const productsIdToRemove = new Set(products.map((c) => c.id));
            newSelectedProducts = selectedProducts.filter((c) => !productsIdToRemove.has(c.id));
          }
          state.products.selectedProducts = newSelectedProducts;
        })
      );
    },
    resetProductsSelected: () => {
      set(
        produce((state) => {
          state.products.selectedProducts = [];
        })
      );
    },
    createProduct: (productData) => {
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .createProduct(productData)
        .then(() => {
          get().products.getProducts();
          get().products.resetProductsSelected();
          set(
            produce((state) => {
              state.products.isCreateProductVisible = false;
            })
          );
          toast.success('Produto criado com sucesso');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    createPublicProduct: (productData) => {
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .createPublicProduct(productData)
        .then(() => {
          set(
            produce((state) => {
              state.products.isCreatePublicProductVisible = false;
            })
          );
          location.pathname.includes('loja')
            ? toast.success('Produto criado com sucesso')
            : toast.success('Visite a loja');
          get().products.getPublicProducts();
        })
        .catch(() => {
          toast.error('Ocorreu um erro durante a criação do produto');
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    updateProduct: async (productId, productData) => {
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .updateProduct(productId, productData)
        .then(() => {
          get().products.getProducts();
          get().products.resetProductsSelected();
          set(
            produce((state) => {
              state.products.isUpdateProductVisible = false;
            })
          );
          toast.success('Produto atualizado com sucesso');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    deletePublicProduct: (productId) => {
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .deletePublicProduct(productId)
        .then(() => {
          get().products.getPublicProducts();
          toast.success('Produto apagado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao apagar produto');
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    },
    deleteProduct: async () => {
      const product = get().products.selectedProduct;
      const id = product?.item?.product_id ?? product?.company_plan?.product_id;
      set(
        produce((state) => {
          state.products.isLoading = true;
        })
      );
      productService
        .deleteProduct(id)
        .then(() => {
          get().products.getProducts();
          get().products.resetProductsSelected();
          set(
            produce((state) => {
              state.products.isDeleteProductVisible = false;
            })
          );
          toast.success('Produto eliminado com sucesso');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.products.isLoading = false;
            })
          );
        });
    }
  }
});
