import { api, apiPublic, authentication } from '../api/api';

const companyService = {
  updateCompany: async (companyData) => {
    try {
      const response = await api.put(`/me-detail`, companyData);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao atualizar dados da empresa');
    }
  },
  updatePaymentMethods: async (paymentMethods) => {
    try {
      const response = await api.put(`/company-payments`, paymentMethods);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao atualizar dados da empresa');
    }
  },
  getPaymentMethods: async () => {
    try {
      const response = await api.get(`/company-payments`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar métodos de pagamento da empresa');
    }
  },
  getCompany: async () => {
    try {
      const response = await api.get(`/me-detail`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da empresa');
    }
  },
  getCompanyResume: async () => {
    try {
      const response = await authentication.get(`/me`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da empresa');
    }
  },
  getCompanyUsers: async () => {
    try {
      const response = await authentication.get(`/users`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da empresa');
    }
  },
  getCompanyTypes: async () => {
    try {
      const response = await apiPublic.get(`/company-type`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar tipos de empresa');
    }
  }
};

export default companyService;
