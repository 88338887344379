import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUploader = ({ setForm, form, field = 'file' }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setForm((prev) => ({
      ...prev,
      [field]: acceptedFiles[0]
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '*/*'
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dashed border-gray-400 rounded text-center cursor-pointer text-gray-400 p-5">
      <input {...getInputProps()} />
      {form.file ? (
        <p>{form.file.name}</p>
      ) : isDragActive ? (
        <p>Solte o anexo aqui ...</p>
      ) : (
        <p>Arraste e solte o anexo, ou clique para selecionar</p>
      )}
    </div>
  );
};

export default FileUploader;
