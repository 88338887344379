import React, { useEffect } from 'react';
import CreditCard from '../CreditCard/CreditCard';
import useResponsivity from '../../hooks/useResponsivity';
import { useStore } from '../../store/useStore';
import Skeleton from 'react-loading-skeleton';
import { Protected } from '../Protected/Protected';

export default function PageHeader({ title }) {
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.company.isLoading);
  const getCompanyResume = useStore((state) => state.company.getCompanyResume);

  useEffect(() => {
    getCompanyResume();
  }, []);

  const { isMobile } = useResponsivity();

  return isMobile ? (
    <div className="mb-5">
      <div>
        <div className="flex gap-4">
          {isLoading ? (
            <div className="w-full md:w-[170px]">
              <Skeleton className="rounded-[10px] h-[50px] md:h-[67px]" />
            </div>
          ) : (
            <CreditCard wallet={company?.wallet ?? '-'} />
          )}
          {/* {isLoading ? (
            <div className="w-full md:w-[170px]">
              <Skeleton className="rounded-[10px] h-[50px] md:h-[67px]" />
            </div>
          ) : (
            <CreditCard title="Envios Whatsapp" value={company?.whatsApp ?? '-'} />
          )}*/}
        </div>
        <h1 className="text-2xl font-bold mt-5">{title}</h1>
      </div>
      <div></div>
    </div>
  ) : (
    <div className="flex justify-between mb-5">
      <div>
        <h1 className="text-[30px] font-bold pb-5">{title}</h1>
      </div>
      <div>
        <div className="flex gap-4">
          {isLoading ? (
            <div className="w-full md:w-[170px]">
              <Skeleton className="rounded-[10px] h-[50px] md:h-[67px]" />
            </div>
          ) : (
            <Protected necessaryPermissions={['reports:read']}>
              <CreditCard wallet={company?.wallet} />
            </Protected>
          )}
          {/*
         {isLoading ? (
          <div className="w-full md:w-[170px]">
            <Skeleton className="rounded-[10px] h-[50px] md:h-[67px]" />
          </div>
        ) : (
          <CreditCard title="Envios Whatsapp" value={company?.whatsApp ?? '-'} />
        )}*/}
        </div>
      </div>
    </div>
  );
}
