import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';

export default function DeleteUserModal() {
  const toggleDeleteUserModal = useStore((state) => state.company.toggleDeleteUserModal);
  const showDeleteUserModal = useStore((state) => state.company.showDeleteUserModal);
  const deleteUser = useStore((state) => state.auth.deleteUser);
  const isLoading = useStore((state) => state.company.isLoading);
  const user = useStore((state) => state.company.user);

  return (
    <Modal
      title="Remover usuário"
      onClose={toggleDeleteUserModal}
      show={showDeleteUserModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          <p>
            Tem certeza que deseja remover o usuário{' '}
            <span className="font-semibold text-zinc-700">{user.email}</span>? <br />
            Depois de feito, você não pode desfazer essa ação.
          </p>
        </div>
      </div>
      <div className="p-5 space-y-2">
        <FilledButton
          className="px-10 w-full"
          onClick={toggleDeleteUserModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={deleteUser}
          loading={isLoading}
          className="px-10 w-full"
          bg={Colors.Red}>
          Remover
        </FilledButton>
      </div>
    </Modal>
  );
}
