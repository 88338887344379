import React from 'react';
import { CloseIcon } from '../../../assets/imgs';

export default function Modal({
  children,
  title,
  subTitle,
  show,
  onClose,
  noPaddingBottom,
  noDefaultHeight = false,
  full = false
}) {
  if (!show) return null;

  return (
    <div
      id="backdrop"
      className="bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center z-50">
      <div
        className={`bg-white ${full ? 'w-full' : 'md:rounded-[10px] w-[540px] md:max-h-[80%]'} ${
          noDefaultHeight ? '' : 'h-full'
        }   flex flex-col overflow-y-hidden ${noPaddingBottom ? 'pb-0' : ''}`}>
        <div className="text-center relative pt-5 ml-5 pb-3 ">
          <div
            onClick={onClose}
            className="cursor-pointer absolute left-0 bg-gray-1 p-3 rounded-sm">
            <CloseIcon width={12} />
          </div>
          <h1 className="font-bold text-2xl px-8">{title}</h1>
          <p className="text-base">{subTitle}</p>
        </div>
        <hr className="border-gray-2" />
        {full ? <div className="max-w-7xl mx-auto">{children}</div> : children}
      </div>
    </div>
  );
}
