import { useEffect, useState } from 'react';
import { PiminderLogo } from '../../assets/imgs';
import { apiPublic, invoices } from '../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { BILLING_LINK_STATUS_LABELS } from '../../utils/constants';
import { formatCurrency } from '../../utils/currencyHelper';
import { distanceBetweenDates, formatDate } from '../../utils/dateHelpers';
import BillingLinkConfirmationModal from './components/BillingLinkConfirmationModal';
import { ConditionalComponent, isExperimentalCustomer } from './components/ConditionalComponent';
import { toast } from 'react-toastify';
import FileUploader from './components/FileUploader';

export default function BillingLink() {
  const navigate = useNavigate();
  const { linkId } = useParams();
  const [billing, setBilling] = useState({
    loading: true,
    data: {},
    payment_method: {
      isBank: false,
      account_number: '-',
      wallet: {
        name: '-'
      }
    },
    difference: 0,
    summary: [],
    previous: null,
    file: null,
    confirmationLoading: false,
    isModalVisible: false,
    phone: null
  });

  useEffect(() => {
    invoices
      .get(`/installment/link/${linkId}`)
      .then((res) => {
        setBilling((prev) => ({
          ...prev,
          data: res.data.message,
          difference: distanceBetweenDates(new Date(res.data.message.due_date)),
          summary: res.data.message.invoice.installments.slice(
            0,
            res.data.message.invoice.installments.findIndex(
              (installment) => installment.id === linkId
            ) + 1
          ),
          previous: res.data.message.invoice.installments.find((installment) => {
            return (
              installment.status === 'WAITINGPAYMENT' &&
              new Date(installment.due_date) < new Date(res.data.message.due_date)
            );
          })
        }));
      })
      .catch((err) => {
        console.error(err);
        navigate('/error');
      })
      .finally(() => {
        setBilling((prev) => ({
          ...prev,
          loading: false
        }));
      });
  }, [linkId]);

  const confirm = () => {
    const formData = new FormData();
    if (billing.file) {
      formData.append(
        'file',
        new Blob([billing.file], { type: billing.file.type }),
        billing.file.name
      );
    }
    formData.append(
      'payment_method',
      billing.payment_method?.wallet?.name ?? billing.payment_method?.bank.name ?? ''
    );
    setBilling((prev) => ({
      ...prev,
      confirmationLoading: true
    }));
    apiPublic
      .post(`/installment/link/${linkId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        setBilling((prev) => ({
          ...prev,
          isModalVisible: true
        }));
      })
      .finally(() => {
        setBilling((prev) => ({
          ...prev,
          confirmationLoading: false
        }));
      });
  };

  const confirmViaMpesa = async () => {
    if (!/^(84|85)\d{7}$/.test(billing.phone)) {
      toast.error('Número de telefone inválido');
      return;
    }
    setBilling((prev) => ({
      ...prev,
      confirmationLoading: true
    }));
    const response = await fetch(
      `https://app.piminder.com/credit/v1/api/credit/wallet/credit?broadcast=true&event_type=payment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          wallet_id: billing.data.wallet_id,
          amount: Number(billing.data.value),
          service_description: `Pagamento da parcela do produto: ${billing.data.title}, referente ao cliente ${billing.data.customer.first_name} ${billing.data.customer.last_name}.`,
          service_referece: billing.data.id,
          customer_reference: billing.data.customer.id,
          method: {
            name: 'mpesa',
            phone_number: billing.phone
          }
        })
      }
    );

    setBilling((prev) => ({
      ...prev,
      confirmationLoading: false
    }));

    if (!response.ok) {
      toast.error('Ocorreu um erro ao realizar pagamento. Tente novamente');
      return;
    }

    setBilling((prev) => ({
      ...prev,
      isModalVisible: true
    }));
  };

  const handleStateChange = (field, method) => {
    switch (field) {
      case 'summary':
        setBilling((prev) => ({
          ...prev,
          summary: prev.data.invoice.installments
        }));
        break;
      case 'previous':
        setBilling((prev) => ({
          ...prev,
          previous: null
        }));
        break;
      case 'payment_method':
        setBilling((prev) => ({
          ...prev,
          payment_method: method
        }));
        break;
      default:
        break;
    }
  };
  return (
    <>
      {billing.loading ? (
        <LoadingState />
      ) : (
        <main className="my-16 max-w-6xl mx-auto open-sans space-y-7">
          <section>
            <div className="bg-orange text-white p-6 rounded-t space-y-3">
              <div>
                <h1 className="text-2xl font-medium">{billing.data.company.name}</h1>
                <h3 className="text-lg font-medium">{billing.data.company.nuit}</h3>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">{billing.data.company.email}</span>
                <span className="text-sm">{billing.data.company.phone}</span>
                <span className="text-sm">
                  {billing.data.company.company_address.address.country.name} -{' '}
                  {billing.data.company.company_address.address.region.name}
                </span>
              </div>
            </div>
            <div className="p-4 shadow flex justify-between items-center">
              <div className="space-x-3 flex items-center">
                <span
                  className={`w-2 h-2 lg:h-4 lg:w-4 rounded-full inline-block bg-orange`}
                  style={{ backgroundColor: BILLING_LINK_STATUS_LABELS[billing.data.status].color }}
                />
                <span>{BILLING_LINK_STATUS_LABELS[billing.data.status].text}</span>
              </div>
              <div>
                <a
                  href={`mailto:${billing.data.company.email}?subject=Reportar um problema&body=Gostaria de reportar o seguinte problema:`}
                  className="underline text-orange">
                  Reportar problema
                </a>
              </div>
            </div>
          </section>

          <section className="p-6 shadow-md rounded-lg space-y-6">
            <div>
              <p className="text-xl font-medium mb-4">Dados da fatura - {billing.data.id}</p>
            </div>
            <div className="space-y-5">
              <div className="flex gap-4 flex-col lg:flex-row">
                <div className="border border-black/15 min-w-[256px] p-6 rounded-lg flex flex-col space-y-4">
                  <span className="text-gray-6 font-semibold">Valor total</span>
                  <span className="text-orange font-semibold">
                    MZN {formatCurrency(billing.data.value)}
                  </span>
                </div>
                <div className="border border-black/15 min-w-[256px] p-6 rounded-lg flex flex-col space-y-4">
                  <span className="text-gray-6 font-semibold">Data de vencimento</span>
                  <span className="text-orange font-semibold">
                    {formatDate(new Date(billing.data.due_date))}
                  </span>
                  <span className="text-sm text-gray-5">
                    {billing.difference === 0
                      ? 'Hoje'
                      : billing.difference > 0
                      ? `Faltam ${billing.difference} dias`
                      : `Venceu há ${billing.difference * -1} dias`}
                  </span>
                </div>
              </div>
              <div className="p-6 border border-black/15 rounded-lg text-gray-6 flex flex-col space-y-4">
                <span className="font-semibold">Título</span>
                <span>{billing.data.title}</span>
              </div>
              <div className="p-6 border border-black/15 rounded-lg text-gray-6 flex flex-col space-y-4">
                <span className="font-semibold">Descrição</span>
                <span>{billing.data.description}</span>
              </div>
              <div className="p-6 border border-black/15 rounded-lg text-gray-6 flex flex-col space-y-4">
                <span className="font-semibold">Parcela</span>
                <span>
                  {billing.data.actual_installment} de {billing.data.invoice.installments.length}
                </span>
              </div>
            </div>
          </section>

          <section className="p-6 shadow-md rounded-lg space-y-6">
            <div>
              <p className="text-xl font-medium mb-4">Dados do comprador</p>
            </div>
            <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0">
              <div className="flex flex-1 flex-col space-y-2">
                <span className="text-gray-5">Nome</span>
                <span className="text-gray-6 font-bold">
                  {billing.data.customer.first_name} {billing.data.customer.last_name}
                </span>
              </div>
              <div className="flex flex-1 flex-col space-y-2">
                <span className="text-gray-5">Email</span>
                <span className="text-gray-6 font-bold">{billing.data.customer.email}</span>
              </div>
            </div>
          </section>

          <section className="p-6 shadow-md rounded-lg space-y-6">
            <div>
              <p className="text-xl font-medium mb-4">Resumo do parcelamento</p>
            </div>
            <div className="space-y-6">
              <ol className=" space-y-3">
                {billing.summary.map((installment, index) => (
                  <li
                    key={index}
                    className={`flex items-center gap-3 ${
                      installment.id === billing.data.id && 'border-y border-gray-300/70 py-1'
                    }`}>
                    <span className="font-bold">{index + 1}</span>
                    <IconStatus status={installment.status} />
                    <span>
                      {formatDate(new Date(installment.due_date))} - MZN{' '}
                      {formatCurrency(installment.value)}
                    </span>
                    {installment.id === billing.data.id && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="!w-4 stroke-gray-6 animate-indicate">
                        <path d="m15 18-6-6 6-6" />
                      </svg>
                    )}
                  </li>
                ))}
              </ol>
              {billing.summary.length < billing.data.invoice.installments.length && (
                <button
                  className="py-2 px-4 rounded hover:bg-orange hover:text-white transition-colors duration-300 border border-orange text-orange"
                  onClick={() => handleStateChange('summary')}>
                  Carregar mais parcelas
                </button>
              )}
            </div>
          </section>

          {billing.data.status !== 'TOBEACCEPT' && billing.data.status !== 'PAID' && (
            <section className="p-6 shadow-md rounded-lg space-y-6">
              <div>
                <p className="text-xl font-medium mb-4">Selecione a forma de pagamento</p>
              </div>
              {billing.previous ? (
                <div className="space-y-6">
                  <div className="p-6 bg-[#FBE1CC] shadow-md text-[#5E2B00] rounded-lg flex gap-4 items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#5E2B00"
                      className="relative top-1 !w-6"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                      <path d="M12 9v4" />
                      <path d="M12 17h.01" />
                    </svg>
                    <div className="space-y-3">
                      <p className="font-bold text-xl">Cobrança anterior aguardando pagamento</p>
                      <p>
                        Identificamos que você possui outra cobrança que ainda não foi paga,
                        visualize a factura anterior para efectuar seu pagamento
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-4 flex-col lg:flex-row">
                    <button
                      onClick={() => handleStateChange('previous')}
                      className="py-2 px-4 rounded hover:bg-gray-6 transition-colors duration-300 text-white bg-gray-5">
                      Pagar a fatura atual
                    </button>
                    <a
                      href={`/cobrancas/link/${billing.previous.id}`}
                      className="py-2 px-4 rounded hover:bg-orange transition-colors duration-300 text-white bg-orange/90 text-center">
                      Visualizar factura anterior
                    </a>
                  </div>
                </div>
              ) : (
                <div className="space-y-6 flex flex-col lg:flex-row items-stretch">
                  <div className="flex-1 space-y-6">
                    <div className="flex gap-3 flex-wrap border-r-0 lg:border-r border-r-gray-3">
                      {billing.data.company.company_wallet.map((wallet) => {
                        return (
                          <button
                            key={wallet.account_number}
                            className={`flex gap-3 py-3 px-4 rounded ${
                              billing.payment_method?.wallet &&
                              wallet.wallet.name === billing.payment_method?.wallet.name
                                ? 'bg-orange'
                                : 'bg-gray-4'
                            } transition-colors duration-300 text-white`}
                            onClick={() =>
                              handleStateChange('payment_method', { isBank: false, ...wallet })
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-white">
                              <rect width="10" height="14" x="3" y="8" rx="2" />
                              <path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" />
                              <path d="M8 18h.01" />
                            </svg>
                            <span>{wallet.wallet.name}</span>
                          </button>
                        );
                      })}
                      {billing.data.company.company_bank.map((bank) => {
                        return (
                          <button
                            key={bank.account_number}
                            className={`flex gap-3 py-3 px-4 rounded ${
                              billing.payment_method?.bank &&
                              bank.bank.name === billing.payment_method?.bank.name
                                ? 'bg-orange'
                                : 'bg-gray-4'
                            } transition-colors duration-300 text-white`}
                            onClick={() =>
                              handleStateChange('payment_method', { isBank: true, ...bank })
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="stroke-white">
                              <rect width="20" height="14" x="2" y="5" rx="2" />
                              <line x1="2" x2="22" y1="10" y2="10" />
                            </svg>
                            <span>{bank.bank.name}</span>
                          </button>
                        );
                      })}
                    </div>
                    <div>
                      <h3 className="text-lg font-medium">
                        {billing.payment_method?.bank?.name || billing.payment_method?.wallet?.name}
                      </h3>
                      <span className="font-medium inline-block mt-4">Número de conta</span>
                      <p>{billing.payment_method?.account_number}</p>
                      {billing.payment_method?.isBank && (
                        <>
                          <span className="font-medium inline-block mt-4">
                            Número de Identificação Bancária
                          </span>
                          <p>{billing.payment_method?.nib}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-center">
                    <ConditionalComponent
                      condition={
                        billing.payment_method?.wallet?.name === 'M-Pesa' &&
                        isExperimentalCustomer(billing.data.company.id)
                      }
                      opposite={
                        <div>
                          <h3 className="font-semibold text-lg mb-3 text-gray-600">
                            Inclua o comprovativo
                          </h3>
                          <FileUploader setForm={setBilling} form={billing} />
                          <button
                            className={`bg-orange text-white px-4 py-2 rounded mt-4 transition-colors duration-300 flex ${
                              billing.confirmationLoading && 'opacity-80'
                            }`}
                            onClick={confirm}>
                            {billing.confirmationLoading && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="animate-spin !w-4 stroke-white mr-2">
                                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                              </svg>
                            )}{' '}
                            <span>Confirmar cobrança</span>
                          </button>
                        </div>
                      }>
                      <div className="w-10/12">
                        <div>
                          <label className="font-medium text-lg text-zinc-600">
                            Número de celular (Mpesa)
                          </label>
                          <input
                            type="text"
                            placeholder="Ex: 841234567"
                            maxLength={9}
                            minLength={9}
                            className="block py-3 px-5 border-zinc-300 border-2 focus:outline-none mt-2"
                            onChange={(e) => setBilling({ ...billing, phone: e.target.value })}
                          />
                        </div>
                        <button
                          className={`bg-orange text-white px-4 py-2 rounded mt-4 transition-colors duration-300 flex ${
                            billing.confirmationLoading && 'opacity-80'
                          }`}
                          onClick={confirmViaMpesa}>
                          {billing.confirmationLoading && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="animate-spin !w-4 stroke-white mr-2">
                              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                            </svg>
                          )}{' '}
                          <span>Confirmar cobrança</span>
                        </button>
                      </div>
                    </ConditionalComponent>
                  </div>
                </div>
              )}
            </section>
          )}

          <section className="p-6 shadow-md rounded-lg space-y-6 text-gray-4">
            <p>
              Esta cobrança é de responsabilidade única e exclusiva de{' '}
              <span className="font-bold">
                {billing.data.company.name} NUIT {billing.data.company.nuit}
              </span>{' '}
              e a Piminder não se responsabiliza pelo produto ou serviço prestado em caso de dúvida
              entre em contato com seu fornecedor.
            </p>
            <hr className="border-gray-3" />
            <p>
              Esta fatura é intermediada pela{' '}
              <a href="https://piminder.com" className="text-orange underline">
                Piminder.com - Suas cobranças mais fáceis
              </a>
            </p>
          </section>
        </main>
      )}
      <BillingLinkConfirmationModal
        onClose={() => window.location.reload()}
        title="Pagamento Confirmado"
        show={billing.isModalVisible}
      />
    </>
  );
}

const LoadingState = () => {
  return (
    <div className="min-h-[100svh] flex justify-center items-center">
      <img src={PiminderLogo} className="animate-pulse w-12 h-12" />
    </div>
  );
};

const IconStatus = ({ status }) => {
  return (
    {
      WAITINGPAYMENT: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="!w-4 stroke-orange"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 6 12 12 16 14" />
        </svg>
      ),
      PAID: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="!w-4 stroke-green">
          <path d="M18 6 7 17l-5-5" />
          <path d="m22 10-7.5 7.5L13 16" />
        </svg>
      ),
      TOBEACCEPT: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="!w-4 stroke-[#33A7E8]"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M20 6 9 17l-5-5" />
        </svg>
      ),
      OVERDUE: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="!w-4 stroke-red">
          <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
          <path d="M12 9v4" />
          <path d="M12 17h.01" />
        </svg>
      )
    }[status] || ''
  );
};
