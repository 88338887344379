import { jwtDecode } from 'jwt-decode';

export function generateBillingLink(billing) {
  const billingLink = new URL(window.location.origin);
  billingLink.pathname = `cobrancas/link/${billing.id}`;
  return billingLink;
}

export function formatPaymentMethods(response) {
  let options = [];
  if (response.company_bank.length > 0) {
    response.company_bank.forEach((bank) => {
      if (options.some((option) => option.label === bank.bank.name)) return;
      options.push({
        label: bank.bank.name,
        value: bank.bank.name
      });
    });
  }
  if (response.company_wallet.length > 0) {
    response.company_wallet.forEach((wallet) => {
      if (options.some((option) => option.label === wallet.wallet.name)) return;
      options.push({
        label: wallet.wallet.name,
        value: wallet.wallet.name
      });
    });
  }
  return options;
}

export function decodeJwt(input) {
  try {
    return jwtDecode(input);
  } catch (_error) {
    return null;
  }
}
