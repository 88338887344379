import { useState } from 'react';

const ImageUploader = ({ setSelectedImage, field = 'image' }) => {
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
      setSelectedImage((prev) => ({ ...prev, [field]: file }));
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      setSelectedImage((prev) => ({ ...prev, [field]: null }));
      setPreviewUrl(null);
    }
  };

  return (
    <div>
      <label className="mb-1 block font-medium">Imagem</label>
      <div className="relative !min-h-[160px] rounded-xl overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center bg-black opacity-50 rounded-xl">
          <button className="text-lg tracking-tighter text-white font-medium">
            Adicionar imagem
          </button>
          <input
            name="image"
            type="file"
            accept="image/*"
            className="inset-0 absolute z-20 opacity-0"
            onChange={handleImageChange}
          />
          {previewUrl && <img src={previewUrl} alt="Preview" className="absolute inset-0 -z-20" />}
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
