import React, { useEffect } from 'react';
import { PlusIcon } from '../../../assets/imgs';
import DetailSection from '../../../components/DetailSection/DetailSection';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import MoreActionsUser from './MoreActionsUsers';

function UsersTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original?.email}</div>
    },
    {
      Header: 'Função',
      accessor: 'role',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original?.role}</div>
    },
    {
      accessor: 'action',
      Cell: ({ row }) => (
        <div>
          <MoreActionsUser user={row.original} asDots />
        </div>
      )
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}
export default function UsersSection() {
  const toggleCreateUserModal = useStore((state) => state.company.toggleCreateUserModal);
  const getCompanyUsers = useStore((state) => state.company.getCompanyUsers);
  const cleanCompanyUsers = useStore((state) => state.company.cleanCompanyUsers);
  const isLoading = useStore((state) => state.company.isLoading);
  const users = useStore((state) => state.company.users);

  useEffect(() => {
    getCompanyUsers();
    return cleanCompanyUsers;
  }, []);
  return (
    <DetailSection
      title={'Usuários'}
      button={
        <OutlinedButton
          onClick={toggleCreateUserModal}
          type="button"
          className="text-base px-2 py-1 md:py-3 md:px-5">
          <div className="flex gap-3 items-center">
            <div>Adicionar</div>
            <PlusIcon color={Colors.Orange} />
          </div>
        </OutlinedButton>
      }>
      <div className="px-5">
        <UsersTable data={users} loading={isLoading} />
      </div>
    </DetailSection>
  );
}
