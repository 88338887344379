import React, { useEffect } from 'react';

import { useStore } from '../../store/useStore';

import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import { Colors } from '../../utils/colors';
import { formatCurrency } from '../../utils/currencyHelper';
import BillingChart from './components/BillingChart';
import PageHeader from '../../components/PageHeader/PageHeader';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import SelectFilter from '../../components/Filters/SelectFilter';
import { ChevronDownIcon } from '../../assets/imgs';
import { DATE_TYPES } from '../../utils/constants';
import { Protected } from '../../components/Protected/Protected';

function DashboardCard({ title, children }) {
  return (
    <div className="border border-gray-2 rounded-[10px] p-3 w-full flex flex-col">
      <div className="font-medium text-lg mb-3">{title}</div>
      <div className="flex flex-col gap-5 h-full">{children}</div>
    </div>
  );
}

function DashboardSubCard({
  title,
  tooltipMessage = undefined,
  value,
  color,
  big,
  loading = false
}) {
  return loading ? (
    <div className="flex-1 grid">
      <Skeleton className="h-full min-h-[81px]" />
    </div>
  ) : (
    <div className="border border-gray-2 rounded-[10px] p-2 flex-1 grid">
      <div className="text-sm flex justify-between group">
        <span>{title}</span>
        {tooltipMessage && (
          <div className="relative">
            <div className="absolute bottom-full right-0 p-3 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto opacity-0 transition-all duration-300 group-hover:opacity-100 bg-gray-3 rounded w-52">
              <p>{tooltipMessage}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#9E9E9E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <circle cx="12" cy="12" r="10" />
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
              <path d="M12 17h.01" />
            </svg>
          </div>
        )}
      </div>
      <div
        className={`text-center ${big ? 'text-5xl' : 'text-3xl md:text-4xl'} justify-self-center`}
        style={{ color }}>
        {value}
      </div>
    </div>
  );
}

export default function Dashboard() {
  const getDashboard = useStore((state) => state.dashboard.getDashboard);

  const customers = useStore((state) => state.dashboard.customers);
  const installments = useStore((state) => state.dashboard.installments);
  const invoicing = useStore((state) => state.dashboard.invoicing);
  const graph = useStore((state) => state.dashboard.graph);
  const isDashboardLoading = useStore((state) => state.dashboard.isDashboardLoading);

  const setFilter = useStore((state) => state.dashboard.setFilter);
  const dateFilter = useStore((state) => state.dashboard.filters.date);
  const dateType = useStore((state) => state.dashboard.filters.date_type);

  useEffect(() => {
    return () => {
      setFilter('date', { startDate: moment().subtract(7, 'days'), endDate: moment() });
    };
  }, []);

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate) getDashboard();
  }, [dateFilter, dateType]);

  const handleChangeDateRangeFilter = ([startDate, endDate]) => {
    setFilter('date', { startDate, endDate });
  };

  const handleChangeDateTypeFilter = (dateType) => {
    setFilter('date_type', dateType.value);
  };

  return (
    <div>
      <div>
        <PageHeader title="Resumo" />
        <Protected necessaryPermissions={['billings:read']}>
          <div className="flex gap-4 flex-wrap">
            <SelectFilter
              onChange={handleChangeDateTypeFilter}
              options={DATE_TYPES}
              iconComponent={ChevronDownIcon}
              placeholder="Filtrar por data"
            />
            <DateRangeFilter onChange={handleChangeDateRangeFilter} />
          </div>
          <div className="flex flex-col md:flex-row justify-between gap-5 mt-5">
            <DashboardCard title="Clientes">
              <DashboardSubCard
                loading={isDashboardLoading}
                title="EM DIA"
                value={customers?.active ?? '-'}
                color={Colors.Green}
                big
              />
              <DashboardSubCard
                loading={isDashboardLoading}
                title="DEVEDORES"
                value={customers?.defaulter ?? '-'}
                color={Colors.Red}
                big
              />
            </DashboardCard>
            <DashboardCard title="Cobranças">
              <DashboardSubCard
                loading={isDashboardLoading}
                title="PREVISTAS"
                value={installments?.planned ?? '-'}
                color={Colors.Yellow}
              />
              <DashboardSubCard
                loading={isDashboardLoading}
                title="VENCIDAS"
                value={installments?.overdue ?? '-'}
                color={Colors.Red}
              />
              <DashboardSubCard
                loading={isDashboardLoading}
                title="RECEBIDAS"
                value={installments?.received ?? '-'}
                color={Colors.Green}
              />
            </DashboardCard>
            <DashboardCard title="Faturamento">
              <DashboardSubCard
                loading={isDashboardLoading}
                title="PREVISTO"
                value={
                  <span>
                    <span className="text-lg">MZN</span>{' '}
                    {invoicing?.planned || invoicing?.planned === 0
                      ? formatCurrency(invoicing?.planned)
                      : '-'}
                  </span>
                }
                color={Colors.Yellow}
                tooltipMessage={
                  'Soma do valor de todas as cobranças. Incluindo as vencidas, aguardando pagamento, à confirmar ou confirmadas.'
                }
              />
              <DashboardSubCard
                loading={isDashboardLoading}
                title="À CONFIRMAR RECEBIMENTO"
                value={
                  <span>
                    <span className="text-lg">MZN</span>{' '}
                    {invoicing?.to_confirm || invoicing?.to_confirm === 0
                      ? formatCurrency(invoicing?.to_confirm)
                      : '-'}
                  </span>
                }
                color={Colors.Blue}
              />
              <DashboardSubCard
                loading={isDashboardLoading}
                title="RECEBIDO"
                value={
                  <span>
                    <span className="text-lg">MZN</span>{' '}
                    {invoicing?.received || invoicing?.received === 0
                      ? formatCurrency(invoicing?.received)
                      : '-'}
                  </span>
                }
                color={Colors.Green}
              />
            </DashboardCard>
          </div>
          <div className="mt-5">
            <DashboardCard title="Faturamento">
              {isDashboardLoading ? (
                <Skeleton height={300} />
              ) : (
                <div className="h-[300px]">
                  <BillingChart data={graph} />
                </div>
              )}
            </DashboardCard>
          </div>
        </Protected>
      </div>
    </div>
  );
}
