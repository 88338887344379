import { Navigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';

export const Protected = ({ necessaryPermissions = [], children, route = false }) => {
  const company = useStore((state) => state.company.company);
  if (
    !necessaryPermissions.every((permission) => company.permissions.includes(permission)) &&
    !company.permissions.includes('*')
  ) {
    return route ? <Navigate to="/unauthorized" replace /> : null;
  }

  return children;
};
