import React, { useEffect } from 'react';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import { Colors } from '../../../utils/colors';
import { PlusIcon } from '../../../assets/imgs';
import Checkbox from '../../../components/FormComponents/Checkbox/Checkbox';
import Skeleton from 'react-loading-skeleton';
import { Protected } from '../../../components/Protected/Protected';

function BankAccountsTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Banco',
      accessor: 'bank',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original?.bank?.name}</div>
    },
    {
      Header: 'Conta Bancária',
      accessor: 'account',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{row.original?.account_number}</div>
      )
    },
    {
      Header: 'NIB',
      accessor: 'nib',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original.nib}</div>
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

function MobileWalletsTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Carteira',
      accessor: 'wallet',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original?.wallet?.name}</div>
    },
    {
      Header: 'Número',
      accessor: 'number',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{row.original?.account_number}</div>
      )
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

const CheckboxSkeleton = () => {
  return (
    <div className="flex gap-2 items-center">
      <Skeleton width={15} height={16} />
      <Skeleton width={100} />
    </div>
  );
};

export default function ReceptionSection() {
  const paymentMethods = useStore((state) => state.company.paymentMethods);
  const isLoading = useStore((state) => state.company.isLoading);
  const getPaymentMethods = useStore((state) => state.company.getPaymentMethods);
  const toogleReceptionModal = useStore((state) => state.company.toogleReceptionModal);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <DetailSection
      title="Recebimento"
      button={
        <Protected necessaryPermissions={['configurations:update']}>
          <OutlinedButton
            onClick={toogleReceptionModal}
            type="button"
            className="text-base px-2 py-1 md:py-3 md:px-5">
            <div className="flex gap-3 items-center">
              <div>Adicionar</div>
              <PlusIcon color={Colors.Orange} />
            </div>
          </OutlinedButton>
        </Protected>
      }>
      <div className="px-5 py-5 flex flex-col md:flex-row gap-5">
        {!isLoading && paymentMethods?.company_bank?.length > 0 ? (
          <div className="flex-[1.5]">
            <DetailSection title="Contas bancárias">
              <div className="px-5">
                <BankAccountsTable data={paymentMethods?.company_bank ?? []} loading={isLoading} />
              </div>
            </DetailSection>
          </div>
        ) : null}
        {!isLoading && paymentMethods?.company_wallet?.length > 0 ? (
          <div className="flex-1">
            <DetailSection title="Carteiras móveis">
              <div className="px-5">
                <MobileWalletsTable
                  data={paymentMethods?.company_wallet ?? []}
                  loading={isLoading}
                />
              </div>
            </DetailSection>
          </div>
        ) : null}
      </div>
      <div className="flex flex-wrap gap-5 p-5 pt-0">
        {isLoading ? (
          <CheckboxSkeleton />
        ) : (
          <Checkbox checked={paymentMethods?.numerary} value="numerary" label="Numerário" />
        )}
      </div>
    </DetailSection>
  );
}
