import React from 'react';
import { DeleteIcon, ThinFileIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import { useStore } from '../../../store/useStore';
import DotsIcon from '../../../assets/imgs/DotsIcon';
import ButtonSelect from '../../Billing/components/ButtonSelect';

const getOptions = () => {
  return [
    {
      value: 'view-user',
      icon: <ThinFileIcon color={Colors.black} />,
      label: 'Visualizar usuário'
    },
    {
      value: 'delete-user',
      icon: <DeleteIcon color={Colors.black} />,
      label: 'Apagar usuário'
    }
  ];
};

export default function MoreActionsUser({ user, asDots }) {
  const toggleDeleteUserModal = useStore((state) => state.company.toggleDeleteUserModal);
  const toggleShowUserModal = useStore((state) => state.company.toggleShowUserModal);
  let options = getOptions();

  const onChooseOption = (option) => {
    switch (option) {
      case 'view-user':
        toggleShowUserModal(user);
        break;
      case 'delete-user':
        console.log(user);
        toggleDeleteUserModal(user);
        break;
    }
  };

  return (
    <ButtonSelect
      options={options}
      label="Mais ações"
      onChange={onChooseOption}
      component={asDots ? DotsIcon : null}
      menuClassName={asDots ? 'left-[-200px] top-[-72px]' : 'left-[-68px]'}
    />
  );
}
