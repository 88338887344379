import Modal from '../../../components/FormComponents/Modal/Modal';
import { useStore } from '../../../store/useStore';
import { PERMISSIONS } from '../../../utils/permissions';

export default function UserModal() {
  const showUserModal = useStore((state) => state.company.showUserModal);
  const toggleShowUserModal = useStore((state) => state.company.toggleShowUserModal);
  const user = useStore((state) => state.company.user);

  return (
    <Modal title={'Visualizar usuário'} show={showUserModal} onClose={toggleShowUserModal}>
      <div className="overflow-y-auto p-5 text-gray-600">
        <UserPermissionsViewer userPermissions={user.permissions} />
      </div>
    </Modal>
  );
}

const UserPermissionsViewer = ({ userPermissions = [] }) => {
  const hasPermission = (permission) => {
    return userPermissions.includes(permission);
  };

  const hasAllSubPermissions = (subPermissions) => {
    return subPermissions.every((sub) => hasPermission(sub.permission));
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Permissões do Usuário</h2>

      <div className="space-y-6">
        {PERMISSIONS.map((category) => {
          const hasAll =
            hasPermission(category.permission) || hasAllSubPermissions(category.subPermissions);

          return (
            <div
              key={category.permission}
              className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
              <div className="p-4 bg-gray-50 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-gray-800">{category.name}</h3>
                  {hasAll && (
                    <span className="px-3 py-1 text-sm font-medium text-green-700 bg-green-100 rounded-full">
                      Acesso Total
                    </span>
                  )}
                </div>
                <p className="mt-1 text-sm text-gray-600">{category.description}</p>
              </div>

              <div className="divide-y divide-gray-200">
                {category.subPermissions.map((subPermission) => {
                  const permitted = hasPermission(subPermission.permission);

                  return (
                    <div
                      key={subPermission.permission}
                      className={`p-4 flex items-start gap-4 transition-colors
                        ${permitted ? 'bg-green-50' : 'bg-gray-50 opacity-75'}`}>
                      {permitted ? <>✅</> : <>❌</>}

                      <div className="flex-1">
                        <h4
                          className={`font-medium ${
                            permitted ? 'text-gray-900' : 'text-gray-600'
                          }`}>
                          {subPermission.name}
                        </h4>
                        <p className="mt-1 text-sm text-gray-600">{subPermission.description}</p>

                        {subPermission.requiredPermission && (
                          <p className="mt-2 text-sm text-gray-500">
                            Requer: {subPermission.requiredPermission}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
